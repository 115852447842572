import React, { Component } from 'react'

import HomeLogo from '../home/logo'
import Socials from '../socials'
import Albums from './albums'

class DiscographyPage extends Component {
  render() {
    return (
      <React.Fragment>
        <HomeLogo />
        <Albums history={this.props.history} params={this.props.match.params} />
        <Socials />
      </React.Fragment>
    )
  }
}

export default DiscographyPage