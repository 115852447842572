import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import HomePage from './home'
import Album from './album'
import Header from './header'
import Discography from './discography'
import News from './news'

class PageRouter extends Component {
  constructor() {
    super()
    this.history = null
  }

  render() {
    return (
      <BrowserRouter history={this.history}>
        <Route path="/" component={Header} />

        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/album/:imagePath?" component={Album} />
          <Route path="/discography/:albumID?" component={Discography} />
          <Route path="/news" component={News} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default PageRouter