import styled from "styled-components"

const CalendarHeader = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  font-family: 'Crete Round', serif;
  margin-top: 2rem;
`

const ContactSection = () => {
  return (
    <section>
      <CalendarHeader>Contact</CalendarHeader>

      <div id="contact" style={{ display: "flex", justifyContent: "center", marginTop: "1.5rem" }}>
        <div data-form-token="65883a956dcdeeb31e317881" className="wf-form-root" />
      </div>
    </section>
  )
}

export default ContactSection
