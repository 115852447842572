import { BibliographyBanner, BibliographyContainer } from "../../styled/bibliography"

const Bibliography = () => {
  return (
    <BibliographyBanner id="mystory">
      <h1> My Story </h1>
      <h2> Lucas Taams - Lucas Nicholas </h2>

      <BibliographyContainer>
      <p>
        Tijdens een lang verblijf in Australië  op zoek naar het grote onbekende en zichzelf viel opeens het kwartje. De jonge singer/songwriter Lucas Nicholas leefde al zijn hele leven tussen de muziek die er door zijn vader met de paplepel werd ingegoten. Toch was het ver weg dat hij inzag dat muziek ook zijn manier is om zijn verhaal te vertellen. Hij besluit op dat moment zijn grootste droom na te jagen.
      </p>

      <p>
        This is the story, that I want to tell.<br/>
        This is a story, that will revel your mind.<br/>
        I put my heart on the line and yet with nothing to find<br/>
        I found a way to survive<br/>
      </p>

      <p>
        Gezegend met een stem die je uit duizenden herkent, komt Lucas Nicholas nu met zijn eigen muzikale verhaal. Geïnspireerd door grootheden als John Mayer, Ed Sheeran en natuurlijk zijn vader. De songs zijn geproduceerd in de MI5 Studios te Hoorn door Jeroen Tenty en Erik Lensink. Naast zijn eigen gitaar en pianospel werd er muzikaal meegewerkt door o.a. Bram Hakkens en Jeroen Overman.
      </p>
      </BibliographyContainer>
    </BibliographyBanner>
  )
}

export default Bibliography
