import styled from 'styled-components'

export const BibliographyBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5em;
  background-color: var(--off-white);
  color: black;
  padding: 2rem;
  background: linear-gradient(-182deg,#dcdacd 58%,#d2ceb5 60%,#e6e5dc calc(60% + 1px));
  padding-top: 8rem;
`

export const BibliographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 45rem;
  background: linear-gradient(2deg, #dcdacd 50.5%, #d2ceb5 52.5%, #e6e5dc calc(52.5% + 1px));
  color: black;
  /* color: var(--dark-text); */
  padding: 2em 3em;
  border-radius: 2px;
  box-shadow: 10px 10px 2px 0 rgba(0,0,0,.1);

  @media only screen and (max-width: 800px) {
    padding: 1.5em .75em;
    margin: .5em -.75em;
  }
`