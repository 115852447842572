import styled from 'styled-components'

const FacebookHeader = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  font-family: 'Crete Round', serif;
  margin-top: 1rem;
  margin-bottom: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid white;
`

const FacebookSection = styled.section`
  border-bottom: 1px solid white;
  padding-bottom: 3rem;
  margin-bottom: 1rem;
`

const Facebook = () => {
  return (
    <FacebookSection>
      <FacebookHeader>News</FacebookHeader>
      <div
        className="wf-fbposts"
        data-integration="63601c4511d6adbd3e031209"
      ></div>
    </FacebookSection>
  )
}

export default Facebook