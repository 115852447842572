import styled from 'styled-components'

export const FrontPicturesBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 2rem 5rem;
  padding: 2rem 0;
  background: radial-gradient(circle at top, #1a1a1a, black);
  padding-top: 2em;
  margin-bottom: -10rem;
`

export const ImgContainer = styled.div`
  position: relative;
  height: 45rem;
  z-index: 10;
  width: min(30em, 100%);
  margin-bottom: 5rem;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 44.5rem;
    opacity: 0;
    background-color: var(--primary-color);
    content: '';
    transition: transform .5s cubic-bezier(0, 0.57, 0.71, 1.04), opacity .3s;
    z-index: 1;
  }

  &:hover:after {
    opacity: .4;
    transform: rotateZ(3deg) translate(4px, 4px);
  }
`

export const FrontImg = styled.img`
  position: relative;
  height: 45rem;
  z-index: 10;
  width: min(30em, 100%);
  object-fit: cover;
  box-shadow: 0 .2rem .4rem rgba(0,0,0,.4);
`