import { useState, useEffect, useRef } from 'react'
import { HeaderStyle, HeaderTextBox, HeaderText, HeaderItems, NavToggleInput, NavToggleLabel, NavStyle, NavLogo } from '../styled/header'
import { Link } from'react-router-dom'

import Logo from '../assets/logos/logo-10p.svg'

// used to only update the state once
let oldScroll = 0

const Header = () => {
  let [ navStyle, setNavStyle ] = useState({})
  let [ headerTextStyle, setHeaderTextStyle ] = useState({})
  let [ headerStyle, setHeaderStyle ] = useState({})

  // used to check if you are viewing the navbar
  const toggleRef = useRef(null)

  const toggleShow = e => {
    if (e.target.checked) {
      setNavStyle({
        transform: "scale(1,1)",
        backgroundColor: oldScroll > 50 ? "rgba(0,0,0,.87)" : "black"
      })
      setHeaderTextStyle({
        opacity: "1",
        transition: "opacity 250ms ease-in-out 250ms"
      })
      if (window.scrollY <= 50) setHeaderStyle({ backgroundColor: "black" })
    } else {
      setNavStyle({})
      setHeaderTextStyle({})
      if (window.scrollY <= 50) {
        setHeaderStyle({})
      } else {
        setHeaderStyle({ backgroundColor: "rgba(0,0,0,.87)" })
      }
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (toggleRef.current.checked) return;
      if (window.scrollY > 50 && oldScroll < 50) {
        setHeaderStyle({ backgroundColor: "rgba(0,0,0,.87)" })
      } else if (window.scrollY < 50 && oldScroll > 50) {
        setHeaderStyle({})
      }

      oldScroll = window.scrollY
    })

    // returned function will be called on component unmount
    return () => window.removeEventListener('mousemove', () => {})
  }, [])

  const renderMyStory = () => {
    if (
      window.location.pathname === "/" &&
      (
        window.innerWidth > 1200 ||
        window.innerWidth < 800
      )
    ) return (
      <>
        <HeaderTextBox onClick={() => {
          let el = document.getElementById("mystory")
          el.scrollIntoView()
          setHeaderStyle({ backgroundColor: "rgba(0,0,0,.87)" })
        }}>
          <HeaderText style={headerTextStyle}>
            My story
          </HeaderText>
        </HeaderTextBox>
        <HeaderTextBox onClick={() => {
          let el = document.getElementById("contact")
          el.scrollIntoView()
          setHeaderStyle({ backgroundColor: "rgba(0,0,0,.87)" })
        }}>
          <HeaderText style={headerTextStyle}>
            Contact
          </HeaderText>
        </HeaderTextBox>
      </>
    )
  }

  return (
    <HeaderStyle style={headerStyle}>
      <Link to="/">
        <NavLogo src={Logo} alt="lucas-nicholas-logo" />
      </Link>
      <NavStyle style={navStyle}>
        <HeaderItems>
          <Link to="/" style={{ textDecoration: "none" }}>
            <HeaderTextBox>
              <HeaderText style={headerTextStyle}>
                Home
              </HeaderText>
            </HeaderTextBox>
          </Link>

          { renderMyStory() }

          <Link to="/discography" style={{ textDecoration: "none" }}>
            <HeaderTextBox>
              <HeaderText style={headerTextStyle}>
                Discography
              </HeaderText>
            </HeaderTextBox>
          </Link>

          <Link to="/album" style={{ textDecoration: "none" }}>
            <HeaderTextBox>
              <HeaderText style={headerTextStyle}>
                Photo album
              </HeaderText>
            </HeaderTextBox>
          </Link>
        </HeaderItems>
      </NavStyle>
      <NavToggleLabel htmlFor="nav-toggle">
        <NavToggleInput ref={toggleRef} onChange={toggleShow} type="checkbox" id="nav-toggle" aria-label="show navigation bar" />
      </NavToggleLabel>
    </HeaderStyle>
  )
}

export default Header