import styled from 'styled-components'

export const SocialsBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const SocialsTitle = styled.h1`
  margin-top: .7em;
  color: var(--light-text);
`

export const SocialsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 4em;
  padding: 2em;

  @media screen and (max-width: 800px) {
    gap: 1em;
  }
`

export const SocialsSmallContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 1em;
  padding: 2em;

  @media screen and (max-width: 800px) {
    gap: .75em;
    padding: .5em;
  }
`

export const SocialsBox = styled.div`
  display: flex;
  justify-self: space-between;
  flex: 0 1 1em;
  gap: 1.5em;
  flex-grow: 1;
  align-items: center;
  padding: 1em;
  background-color: var(--off-white);
  color: var(--dark-text);
  cursor: pointer;
  border-radius: 1em;
  transition: transform .3s ease-in-out;
  font-size: .8em;
  transform: translateY(0em);
  backface-visibility: hidden;

  &:hover {
    transform: translateY(-.15em);
  }

  h2 {
    width: max-content;
  }

  @media screen and (max-width: 800px) {
    border-radius: 5em;
  }

  @media screen and (max-width: 800px) {
    h2 {
      display: none;
    }
  }
`

export const SocialsSVG = styled.svg`
  height: 3em;
  width: 3em;
`

export const SocialsSmallSVG = styled.svg`
  height: 2em;
  width: 2em;

  @media screen and (max-width: 800px) {
    height: 1em;
    width: 1em;
  }
`