import styled from 'styled-components'

const CalendarHeader = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  font-family: 'Crete Round', serif;
  margin-top: 2rem;
`

const Calendar = () => {
  return (
    <section id="calendar">
      <CalendarHeader>Calendar</CalendarHeader>
      <div
        className="wf-gcalendar"
        data-integration="63728d9ff61aacdf1bbe9e62"
      ></div>
    </section>
  )
}

export default Calendar