import React, { Component } from 'react'
import path from 'path'

import { AlbumContainer, ImageContainer, ImageWrapper, PhotoAlbumPopupContainer, CloseBox, ImageShowCase, PreviousPhotoArrow, NextPhotoArrow, DateText } from '../../styled/photoAlbumStyled'
import Photo from './photo'

const images = [
  {
    path: "studio1-1280x1920-clr.jpg",
    title: "lucas-playing-in-the-studio",
    date: new Date("8-12-2021")
  },
  {
    path: "studio2-1280x1920.jpg",
    title: "lucas-holding-his-guitar",
    date: new Date("8-12-2021")
  },
  {
    path: "studio4-1281x1920.jpg",
    title: "lucas-playing-the-guitar-in-the-studio",
    date: new Date("8-12-2021")
  },
  {
    path: "studio5-1281x1920.jpg",
    title: "lucas-playing-the-guitar-in-the-studio",
    date: new Date("8-12-2021")
  },
  {
    path: "studio6-1281x1920.jpg",
    title: "lucas-playing-the-piano-in-the-studio",
    date: new Date("8-12-2021")
  },
  {
    path: "kerkhuys-2022-1.jpeg",
    title: "lucas-performing-at-t-kerkhuys",
    date: new Date("11-26-2022")
  },
  {
    path: "kerkhuys-2022-2.jpeg",
    title: "lucas-performing-at-t-kerkhuys",
    date: new Date("11-26-2022")
  },
  {
    path: "zomerpop-2022.jpeg",
    title: "lucas-performing-at-zomerpop",
    date: new Date("9-3-2022")
  },
  {
    path: "LN-pardoes-10-11-1.webp",
    title: "lucas-performing-at-pardoes",
    date: new Date("11-1-2023")
  },
  {
    path: "LN-pardoes-10-11-2.webp",
    title: "lucas-performing-at-pardoes",
    date: new Date("11-1-2023")
  },
  {
    path: "LN-pod-victorie.webp",
    title: "lucas-performing-at-podium-victorie",
    date: new Date("4-22-2023")
  },
  {
    path: "pers-lucas-nicholas-1.webp",
    title: "lucas-nicholas",
    date: new Date("1-12-2024")
  },
  {
    path: "pers-lucas-nicholas-2.webp",
    title: "lucas-nicholas",
    date: new Date("1-12-2024")
  },
  {
    path: "pers-lucas-nicholas-3.webp",
    title: "lucas-nicholas",
    date: new Date("1-12-2024")
  },
  {
    path: "pers-lucas-nicholas-4.webp",
    title: "lucas-nicholas",
    date: new Date("1-12-2024")
  },
  {
    path: "pers-lucas-nicholas-5.webp",
    title: "lucas-nicholas",
    date: new Date("1-12-2024")
  }
]

class PhotoAlbum extends Component {
  constructor() {
    super()

    this.state = {
      showImage: undefined
    }

    this.pathPrefix = "/pa/"
    this.urlBase = "/album"
  }

  componentDidMount = () => {
    // this.urlBase = this.props.location.path
    if (this.props.match.params?.imagePath) {
      const imagePath = this.props.match.params.imagePath,
            index = images.findIndex(v => v.path = path.basename(imagePath, path.extname(imagePath)))

      if (typeof index === 'number') this.showImagePopup(index)
    }
  }

  showImagePopup = i => {
    this.setState({ showImage: i })
    this.props.history.push( this.urlBase + "/" + path.basename(images[i].path, path.extname(images[i].path)) )
  }

  closeImage = () => {
    this.props.history.push(this.urlBase)
    this.setState({ showImage: undefined })
  }

  previousImage = () => {
    this.showImagePopup( this.state.showImage - 1 )
  }

  nextImage = () => {
    this.showImagePopup( this.state.showImage + 1 )
  }

  renderImagePopup = () => {
    if (typeof this.state.showImage === "number") {
      const selectedImage = this.state.showImage,
            totalImages = images.length,
            { path, title, date } = images[selectedImage]
      document.body.style.overflow = "hidden"
      return (
        <PhotoAlbumPopupContainer>
          <CloseBox onClick={this.closeImage} />
          {
            selectedImage !== 0 ? <PreviousPhotoArrow onClick={this.previousImage} /> : ""
          }
          {
            selectedImage < totalImages - 1 ? <NextPhotoArrow onClick={this.nextImage} /> : ""
          }

          <ImageShowCase src={this.pathPrefix + path} alt={title} />

          {
            typeof date === "object" ? <DateText> { date.toLocaleDateString("NL-nl") } </DateText> : ""
          }
        </PhotoAlbumPopupContainer>
      )
    } else {
      document.body.style.overflow = "auto"
    }
  }

  render() {
    return (
      <AlbumContainer>
        { this.renderImagePopup() }
        <ImageContainer>
          {
            images.sort((a, b) => b.date - a.date).map((({path, title}, i) => <Photo key={"albumimage" + i} id={i} path={this.pathPrefix + path} title={title} showPopup={this.showImagePopup} />))
          }
          <ImageWrapper /><ImageWrapper /><ImageWrapper /><ImageWrapper />
        </ImageContainer>
      </AlbumContainer>
    )
  }
}

export default PhotoAlbum