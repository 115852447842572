import React, { Component } from 'react'
import axios from 'axios'
import getSpotifyToken from '../../functions/spotifyToken'

import Album from './album'

import { AlbumDevider, AlbumsBanner } from '../../styled/discographyStyled'

class Albums extends Component {
  constructor() {
    super()
    this.state = {
      albums: []
    }

    this.overrideAlbumInfo = [
      { // lifeline
        appleMusicURI: "https://music.apple.com/nl/album/lifeline-single/1554639776",
        spotifyId: "1PKGpVazHEcFWdmCDPXvi6"
      },
      { // someone else
        appleMusicURI: "https://music.apple.com/nl/album/someone-else/1645874539?i=1645874552",
        spotifyId: "6aSk8NcAg3sh3Ca9KM5UfM"
      },
      { // story
        appleMusicURI: "https://music.apple.com/nl/album/story/1668355448?i=1668355909",
        spotifyId: "2JQwnQbXage73b3FExCU7Y"
      }
    ]
  }

  componentDidMount = () => {
    this.getSpotifyArtistAlbums()
  }

  getSpotifyArtistAlbums = async () => {
    if (!sessionStorage.getItem("spotify_token")) await getSpotifyToken()

    return axios({
      method: "GET",
      url: "https://api.spotify.com/v1/artists/60df08YRf2Oh7A2PUK24Vi/albums",
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("spotify_token")
      }
    })
    .then(response => {
      const { items } = response.data

      let albums = []

      items.forEach(v => {
        let overrideInfo = this.overrideAlbumInfo.filter(a => a.spotifyId === v.id)[0]

        let info = {
          title: v.name,
          type: v.album_type,
          releaseDate: new Date(v.release_date),
          cover: v.images.filter(v => v.height === "640")[0]?.url || v.images[0].url,
          spotifyURI: v.external_urls.spotify
        }

        if (overrideInfo) {
          Object.assign(info, overrideInfo)
        }

        albums.push(info)
      })

      this.setState({ albums })
    })
    .catch(err => {
      console.error(err)
    })
  }

  renderAlbums = () => {
    return this.state.albums
    .sort((a, b) => b.releaseDate - a.releaseDate)
    .map(v => (
      <React.Fragment key={v.title}>
        <Album info={v} history={this.props.history} params={this.props.params} />
        <AlbumDevider />
      </React.Fragment>
    ))
  }

  render() {
    return (
      <AlbumsBanner>
        <AlbumDevider />
        { this.renderAlbums() }
      </AlbumsBanner>
    )
  }
}

export default Albums