import React, { Component } from 'react'

import { PhotoAlbumImg, ImageWrapper } from '../../styled/photoAlbumStyled'

class Photo extends Component {
  constructor() {
    super()

    this.state = {
      showImage: undefined,
      imgAlt: undefined
    }
  }

  // showImagePopup = e => {
  //   this.setState({ showImage: e.target.src, imgAlt: e.target.alt })
  // }

  render() {
    const { id, path, title } = this.props

    return (
      <ImageWrapper onClick={() => this.props.showPopup(id)}>
        <PhotoAlbumImg src={path} alt={title} loading="lazy" />
      </ImageWrapper>
    )
  }
}

export default Photo