import styled, { keyframes } from 'styled-components'

import dashed_12_24 from '../assets/tools/dashed_12_24.svg'
import dashed_12_36 from '../assets/tools/dashed_12_36.svg'
import { SocialsBox, SocialsSVG, SocialsContainer } from './socials'

export const AlbumsBanner = styled.div`
  width: 100%;
  margin-top: 3em;
`

export const AlbumDevider = styled.div`
  width: 50%;
  height: 1px;
  background-color: var(--off-white);
  margin: 1em auto;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: calc(50% - .15em);
    top: calc(-.15em + .5px);
    height: .3em;
    width: .3em;
    background-color: white;
    transform: rotateZ(45deg);
  }
`

export const AlbumContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 0 1rem;

  div:first-child {
    justify-self: flex-end;
  }
  div:last-child {
    justify-self: flex-start;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;

    div:first-child {
      justify-self: center;
    }
    div:last-child {
      min-width: 14.5rem;
      justify-self: center;
    }
  }
`

const SpinAnimation = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`

export const AlbumCoverEffect = styled.div`
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: .75em;
    left: .75em;
    z-index: 999;
    width: 13.5em;
    height: 13.5em;
    background-image: url(${dashed_12_36});
    opacity: 0;
    transition: opacity .6s;
    clip-path: stroke-box;
    animation: ${SpinAnimation} 35s infinite linear;
  }
  
  &::after {
    background-image: url(${dashed_12_24});
    top: 2em;
    left: 2em;
    width: 11em;
    height: 11em;
    animation-duration: 28s;
  }

  &:hover::after,
  &:hover::before,
  &.playing::after,
  &.playing::before {
    opacity: .5;
  }

  &:hover img,
  &.playing img {
    border-radius: 10em;
  }

  div {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: .65;
  }

  div::before,
  div::after {
    content: '';
    position: absolute;
    top: calc(50% - 25px);
    left: 45%;
    width: 7px;
    height: 50px;
    transition: 
      background-color .2s,
      box-shadow .2s,
      transform .2s ease-out,
      top .2s ease-out,
      left .2s ease-out,
      height .2s ease-out;
    background-color: transparent;
    box-shadow: 0px 0px var(--primary-color);
  }

  div::after {
    left: 55%;
  }

  &:hover div::before,
  &:hover div::after {
    background-color: white;
    box-shadow: 2px 2px var(--primary-color);
  }

  &.playing div::before,
  &.playing div::after {
    transform: rotateZ(-45deg);
    left: 50%;
    top: 46.25%;
    top: calc(50% - 27.7px);
    height: 35px;
    background-color: white;
    box-shadow: 2px 2px var(--primary-color);
  }
  &.playing div::after {
    transform: rotateZ(45deg);
    top: 53.75%;
    top: calc(50% - 7.7px);
  }
`

export const AlbumCover = styled.img`
  width: 15rem;
  height: 15rem;
  transition: border-radius .5s cubic-bezier(.08,.82,.17,1);
`

export const AlbumDescriptionContainer = styled.div`

`

export const AlbumLinksContainer = styled(SocialsContainer)`
  gap: 0 .75rem;
  justify-content: flex-start;
  height: 100%;
  padding: .5em 0;
  width: max-content;

  p:nth-child(2) {
    text-transform: capitalize;
  }
`

export const AlbumLinksBox = styled(SocialsBox)`
  padding: .75rem;
`

export const AlbumLinksSVG = styled(SocialsSVG)`
  width: 2rem;
  height: 2rem;
`