import { useEffect } from 'react'

import HomeLogo from './logo'
import FrontImage from './frontPictures'
import Bibliography from './bibliography'
import Socials from '../socials'
import NewRelease from './newRelease'
import Calendar from './calendar'
import Facebook from './facebook'
import ContactSection from '../contact'
// import InstagramFeed from '../instagram'

const HomePage = () => {
  useEffect(() => {
    const scriptId = "wf-sdk"
    if (document.getElementById(scriptId)) return

    const scriptNode = document.createElement("script")
    scriptNode.src = "https://integrations.web-fuse.nl/v1/sdk.js"
    scriptNode.id = scriptId
    document.head.appendChild(scriptNode)
  }, [])

  return (
    <div className="App">
      <HomeLogo />
      <NewRelease />
      <FrontImage />
      <Bibliography />
      <ContactSection />
      <Calendar />
      <Facebook />
      <Socials />
    </div>
  )
}

export default HomePage