import React, { Component } from 'react'

import HomeLogo from '../home/logo'
import Socials from '../socials'
// import InstagramFeed from '../instagram'

class NewsPage extends Component {
  render() {
    return (
      <div className="App">
        <HomeLogo />
        {/* <InstagramFeed /> */}
        <Socials />
      </div>
    )
  }
}

export default NewsPage