import React, { Component } from 'react'
import { Route } from 'react-router-dom'

import PhotoAlbumHeaderText from './headerText'
import PhotoAlbum from './photoAlbum'

class AlbumPage extends Component {
  render() {
    return (
      <React.Fragment>
        <PhotoAlbumHeaderText />
        <Route component={PhotoAlbum} />
      </React.Fragment>
    )
  }
}

export default AlbumPage