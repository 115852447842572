import axios from 'axios'

const getSpotifyToken = () => {
  return new Promise((resolve, reject) => {
    const formdata = new URLSearchParams()
    formdata.append("grant_type", "client_credentials")

    const XHRconfig = {
      method: "POST",
      url: "https://accounts.spotify.com/api/token",
      headers: { Authorization: "Basic OTFlMDg0ZjMwNjM1NGM0YmE0NmQ2MDI5YjI5YWE0MmI6M2RhYjUyYzA4MjFmNGJmNDkyNGI2OGNlMzgzZTJmYTI=" },
      data: formdata
    }

    return axios(XHRconfig)
    .then(response => {
      sessionStorage.setItem("spotify_token", response.data.access_token)
      return resolve()
    })
    .catch(() => reject(new Error("Can not get spotify token")))
  })
}

export default getSpotifyToken