import { useState, useEffect } from 'react'
import axios from 'axios'
import { LogoBox, LogoImg, LogoSubtext, VideoBackground, LogoWrapper, SocialsWrapper } from '../../styled/homeLogo'
import getSpotifyToken from '../../functions/spotifyToken'

import logoWide from '../../assets/logos/text-horizontal.svg'
import SomeoneElseVideo from '../../assets/video/someone-else.mp4'
import SocialsSmall from '../socialsSmall'

const HomeLogo = () => {
  const [ latestAlbumInfo, setLatestAlbumInfo ] = useState({})

  useEffect(() => {
    getSpotifyArtistAlbums()
  }, [])

  const getSpotifyArtistAlbums = async () => {
    if (!sessionStorage.getItem("spotify_token")) await getSpotifyToken()

    return axios({
      method: "GET",
      url: "https://api.spotify.com/v1/artists/60df08YRf2Oh7A2PUK24Vi/albums",
      params: {
        limit: 1
      },
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("spotify_token")
      }
    })
    .then(response => setLatestAlbumInfo(response.data.items[0]))
    .catch(err => {
      console.error(err)
      setLatestAlbumInfo()
    })
  }

  const chooseSubText = () => {
    if (latestAlbumInfo?.name) return `${latestAlbumInfo.name} - Out Now!`
    else if (typeof latestAlbumInfo === "object") return ""
    return "Lifeline - Out Now!"
  }

  return (
    <LogoBox id="logobox">
      <VideoBackground>
        <video autoPlay muted loop playsInline style={{ width: "100%", height: "100%", objectFit: "cover" }}>
          <source src={SomeoneElseVideo} type="video/mp4" />
          Sorry, your browser doesn't support embedded videos, but don't worry, you can <a href={SomeoneElseVideo}>download it</a> and watch it with your favorite video player!
        </video>
      </VideoBackground>
      <SocialsWrapper>
        <SocialsSmall/>
      </SocialsWrapper>
      <LogoWrapper>
        <LogoImg src={logoWide} alt="lucas-nicholas-logo-wide" />
      </LogoWrapper>
      <LogoSubtext> { chooseSubText() } </LogoSubtext>
    </LogoBox>
  )
}

export default HomeLogo