import styled from 'styled-components'

export const HeaderStyle = styled.div`
  text-align: center;
  z-index: 999;
  width: 100%;
  position: sticky;
  top: 0;
  transition: background-color .3s;

  @media screen and (min-width: 800px) {
    display: grid;
    grid-template-columns: 1fr auto minmax(600px, 3fr) .4fr;
  }
`

export const NavStyle = styled.div`
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background: rgba(0,0,0,.87);
  width: 100%;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 400ms ease-in-out;

  @media screen and (min-width: 800px) {
    /* the following lines are not from my video, but add Edge support */
    position: relative;
    text-align: left;
    transition: none;
    transform: scale(1,1);
    background: none;
    top: initial;
    left: initial;
    /* end Edge support stuff */

    grid-column: 3 / 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`

export const HeaderItems = styled.div`
  @media screen and (min-width: 800px) {
    display: flex;
    gap: min(3em, 4vw);
  }
`

export const HeaderTextBox = styled.div`
  margin-bottom: 1em;
  margin-left: 1em;
  cursor: pointer;

  @media screen and (min-width: 800px) {
    margin-left: unset;
    margin-bottom: unset;
  }
`

export const HeaderText = styled.h2`
  color: white;
  width: max-content;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  position: relative;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 0%;
    background-color: white;
    display: block;
    content:'';
    transition: width .4s cubic-bezier(0.8, 0, 0.4, 1);
  }

  &:hover::after{
    width: calc(100% + .3em);
  }

  @media screen and (min-width: 800px) {
    opacity: 1;
    position: relative;
  }
`

export const NavLogo = styled.img`
  margin: .5rem auto;
  height: 3.5rem;
  width: 3.5rem;
`

export const NavToggleInput = styled.input`
  display: block;
  background: white;
  height: .2em;
  width: 2em;
  border-radius: 1em;
  position: relative;
  appearance: none;
  outline: unset;
  transition: transform .4s ease-in-out, background-color .5s;

  &::after,
  &::before {
    display: block;
    background: white;
    height: .2em;
    width: 2em;
    border-radius: 1em;
    position: relative;
    content: '';
    position: absolute;
    transition: transform .4s ease-in-out;
  }

  &::after {
    transform: translateY(-.6em)
  }

  &::before {
    transform: translateY(.6em)
  }

  &:checked {
    transform: translateX(-2em);
    background: transparent;
  }

  &:checked::after {
    transform: rotateZ(45deg) translate(1.4em, -1.4em);
  }

  &:checked::before {
    transform: rotateZ(-45deg) translate(1.4em, 1.4em);
  }
`

export const NavToggleLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;

  @media screen and (min-width: 800px) {
    display: none;
  }
`