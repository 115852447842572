import { FrontImg, FrontPicturesBox, ImgContainer } from "../../styled/frontPictures"

const FrontImage = () => {
  return (
    <FrontPicturesBox>
      <ImgContainer>
        <FrontImg src="/pa/studio1-1280x1920-clr.jpg" alt="lucas-nicholas-in-garden" />
      </ImgContainer>
      <ImgContainer>
        <FrontImg src="/pa/studio2-1280x1920.jpg" alt="lucas-nicholas-in-the-studio" />
      </ImgContainer>
      <ImgContainer>
        <FrontImg src="/pa/studio5-1281x1920.jpg" alt="lucas-nicholas-in-the-studio" />
      </ImgContainer>
    </FrontPicturesBox>
  )
}

export default FrontImage