import styled, { keyframes } from 'styled-components'

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  font-size: 1.5rem;
  margin: 3rem 0;

  @media only screen and (max-width: 600px) {
    img {
      width: 90vw;
      height: unset;
    }
    h1 {
      font-size: 2rem;
    }
  }
`

export const AlbumContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0 0 0;
  overflow: hidden;
  background: linear-gradient(transparent 50%, rgba(255,255,255,.03));
  padding-bottom: 2rem;
`

export const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  max-width: 90vw;
  padding-top: 1em;
  cursor: pointer;
`

export const ImageWrapper = styled.div`
  flex: 1 1 20em;
  max-width: 30em;
  overflow: hidden;
`

export const PhotoAlbumImg = styled.img`
  max-width: 100%;
  min-height: 100%;
  object-fit: cover;
  transition: box-shadow .3s ease-in-out, transform .4s ease-in-out;

  &:hover {
    box-shadow: 0 .3em .6em -.2em #c4c24111;
    transform: scale(1.05);
  }
`

const PopupZoom = keyframes`
  0% {
    top: 8vh;
    left: 8vw;
    height:84vh;
    width: 84vw;
    opacity: 0;
  }
  100% {
    top: 5vh;
    left: 5vw;
    height: 90vh;
    width: 90vw;
    opacity: 1;
  }
`

export const PhotoAlbumPopupContainer = styled.div`
  position: fixed;
  top: 5vh;
  left: 5vw;
  height: 90vh;
  width: 90vw;
  background-color: var(--off-white-tp);
  border-radius: 1em;
  box-shadow: .5em .5em 1em rgba(0,0,0,.5);
  z-index: 1000;
  animation: ${PopupZoom} .2s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 800px) {
    width: 100vw;
    left: 0;
  }
`

export const ImageShowCase = styled.img`
  flex-grow: 1;
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;

  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
`

export const CloseBox = styled.span`
  position: absolute;
  top: .5em;
  right: .5em;
  height: 2.5em;
  width: 2.5em;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: .2em;
    width: 2em;
    margin-top: 1.15em;
    margin-left: .25em;
    background-color: var(--primary-color);
    transform: rotateZ(45deg);
    transition: transform .3s ease-in-out;
  }

  &:hover::before {
    transform: rotateZ(40deg);
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: .2em;
    width: 2em;
    margin-top: 1.15em;
    margin-left: .25em;
    background-color: var(--primary-color);
    transform: rotateZ(-45deg);
    transition: transform .3s ease-in-out;
  }

  &:hover::after {
    transform: rotateZ(-40deg);
  }
`

export const NextPhotoArrow = styled.span`
  position: absolute;
  top: 50%;
  right: .5rem;
  height: 4rem;
  width: 3rem;
  transform: translateY(-50%);

  &::before, &::after {
    content: '';
    position: absolute;
    left: 50%;
    height: 1.5rem;
    width: .2rem;
    background-color: var(--primary-color);
    transform: rotateZ(-30deg);
    transition:
      height .3s ease-out,
      transform .3s ease-out;
  }

  &::before {
    bottom: 1.85rem;
  }

  &::after {
    top: 1.85rem;
    transform: rotateZ(30deg);
  }

  &:hover::before,
  &:hover::after {
    height: 1.8rem;
  }

  &:hover::before {
    transform: rotateZ(-30deg) translateX(-.1rem);
  }

  &:hover::after {
    transform: rotateZ(30deg) translateX(-.1rem);
  }

  @media only screen and (max-width: 800px) {
    right: 0;
  }
`

export const PreviousPhotoArrow = styled(NextPhotoArrow)`
  right: unset;
  left: .5rem;

  &::before, &::after {
    left: unset;
    right: 50%;
  }

  &::before {
    transform: rotateZ(30deg);
  }

  &::after {
    transform: rotateZ(-30deg);
  }

  &:hover::before {
    transform: rotateZ(30deg) translateX(-.1rem);
  }

  &:hover::after {
    transform: rotateZ(-30deg) translateX(-.1rem);
  }

  @media only screen and (max-width: 800px) {
    left: 0;
  }
`

export const DateText = styled.p`
  position: absolute;
  bottom: .2rem;
  color: black;
  font-size: 1.2rem;
`