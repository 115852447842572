import styled from "styled-components"
import { SocialsBox, SocialsSmallSVG, SocialsSmallContainer } from "../../styled/socials"

import Announcement from '../../assets/announcements/the-boy-next-door-lucas-nicholas-hollywood.webp'

const ReleaseContainer = styled.div`
  padding: 4rem auto 1rem auto;
  display: flex;
  gap: 2rem 4rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  /* background-color: black; */
  background: radial-gradient(circle,#111,black);
  padding: 4rem 1rem 1rem 1rem;
`

const Image = styled.img`
  width: clamp(25vw, 25rem, 85vw);
  height: auto;
  display: inline-block;
  box-shadow: 5px -15px 80px -20px rgba(193, 116, 160, .45);
`

const TextContainer = styled.div`
  text-align: center;
`

const ConcertCard = styled.div`
  background: linear-gradient(20deg, rgba(50,45,50,.4) 30%, rgba(90,80,70,.4));
  padding: 2.2rem 1.8rem;
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  border-radius: 6px;
  box-shadow: inset 2px 0 24px rgba(255,255,255,.1);
  max-width: 800px;

  > h2 {
    margin-bottom: 1.5rem;
  }

  > div {
    margin: .25rem;

    > p {
      margin-top: .2rem;
    }
  }
`

const TicketButton = styled(SocialsBox)`
  padding-left: 1.5rem;

  @media screen and (max-width: 800px) {
    h2 {
      display: block;
    }
  }
`

const ConcertInfo = () => (
  <ConcertCard>
    <h2>Lucas Nicholas in Concert!</h2>
    <p>
      Tijdens een lang verblijf in Australië op zoek naar het grote onbekende en zichzelf viel opeens het kwartje. De jonge singer/songwriter Lucas Nicholas leefde al zijn hele leven tussen de muziek die er door zijn vader met de paplepel werd ingegoten. Toch was het ver weg dat hij inzag dat muziek ook zijn manier is om zijn verhaal te vertellen. Hij besluit op dat moment zijn grootste droom na te jagen.
    </p>

    <div>
      <p>This is the story, that I want to tell.</p>
      <p>This is a story, that will revel your mind.</p>
      <p>I put my heart on the line and yet with nothing to find</p>
      <p>I found a way to survive</p>
    </div>

    <p>
      Gezegend met een stem die je uit duizenden herkent, komt Lucas Nicholas nu met zijn eigen muzikale verhaal. Geïnspireerd door grootheden als John Mayer, Ed Sheeran en natuurlijk zijn vader. De songs zijn geproduceerd in de MI5 Studios te Hoorn door Jeroen Tenty en Erik Lensink. Naast zijn eigen gitaar en pianospel werd er muzikaal meegewerkt door o.a. Bram Hakkens en Jeroen Overman.
    </p>

      <a href="https://www.podiumpardoes.nl/event/lucas-nicholas/" rel="noreferrer noopener" target="_blank">
        <TicketButton>
          <h2>Tickets en meer info</h2>
        </TicketButton>
      </a>
  </ConcertCard>
)

const NewRelease = () => {
  if (new Date() < new Date("2023-11-11")) return <ReleaseContainer><ConcertInfo /></ReleaseContainer>

  return <ReleaseContainer>
    <a href="https://open.spotify.com/track/4paM0TycOmI7HaE8uZsZjw?si=bf58963547494b16" rel="noreferrer noopener" target="_blank">
      <Image src={Announcement} width="700" height="700" alt="Hollywood released" />
    </a>
    <TextContainer>
      <h2>Listen now</h2>
      <SocialsSmallContainer>
        <a href="https://music.apple.com/nl/album/hollywood-single/1721848963" rel="noreferrer noopener" target="_blank">
          <SocialsBox>
            <SocialsSmallSVG alt="Apple-music-logo">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 456.008 560.035"><path d="M380.844 297.529c.787 84.752 74.349 112.955 75.164 113.314-.622 1.988-11.754 40.191-38.756 79.652-23.343 34.117-47.568 68.107-85.731 68.811-37.499.691-49.557-22.236-92.429-22.236-42.859 0-56.256 21.533-91.753 22.928-36.837 1.395-64.889-36.891-88.424-70.883-48.093-69.53-84.846-196.475-35.496-282.165 24.516-42.554 68.328-69.501 115.882-70.192 36.173-.69 70.315 24.336 92.429 24.336 22.1 0 63.59-30.096 107.208-25.676 18.26.76 69.517 7.376 102.429 55.552-2.652 1.644-61.159 35.704-60.523 106.559M310.369 89.418C329.926 65.745 343.089 32.79 339.498 0 311.308 1.133 277.22 18.785 257 42.445c-18.121 20.952-33.991 54.487-29.709 86.628 31.421 2.431 63.52-15.967 83.078-39.655"/></svg>
            </SocialsSmallSVG>
            <h2>Music</h2>
          </SocialsBox>
        </a>
        <a href="https://open.spotify.com/album/5KkxAwJUnGl2480t9lHYsV?si=B2Hf6KDISxyz2M7_uTEpcw" rel="noreferrer noopener" target="_blank">
          <SocialsBox>
            <SocialsSmallSVG alt="Apple-music-logo">
              <svg id="Bold" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 24c6.624 0 12-5.376 12-12s-5.376-12-12-12-12 5.376-12 12 5.376 12 12 12zm4.872-6.344v.001c-.807 0-3.356-2.828-10.52-1.36-.189.049-.436.126-.576.126-.915 0-1.09-1.369-.106-1.578 3.963-.875 8.013-.798 11.467 1.268.824.526.474 1.543-.265 1.543zm1.303-3.173c-.113-.03-.08.069-.597-.203-3.025-1.79-7.533-2.512-11.545-1.423-.232.063-.358.126-.576.126-1.071 0-1.355-1.611-.188-1.94 4.716-1.325 9.775-.552 13.297 1.543.392.232.547.533.547.953-.005.522-.411.944-.938.944zm-13.627-7.485c4.523-1.324 11.368-.906 15.624 1.578 1.091.629.662 2.22-.498 2.22l-.001-.001c-.252 0-.407-.063-.625-.189-3.443-2.056-9.604-2.549-13.59-1.436-.175.048-.393.125-.625.125-.639 0-1.127-.499-1.127-1.142 0-.657.407-1.029.842-1.155z"/></svg>
            </SocialsSmallSVG>
            <h2>Spotify</h2>
          </SocialsBox>
        </a>
      </SocialsSmallContainer>
    </TextContainer>
  </ReleaseContainer>
}

export default NewRelease
