import styled from 'styled-components'

export const LogoBox = styled.div`
  --video-height: 20rem;

  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  height: var(--video-height);

  @media only screen and (min-width: 800px) {
    --video-height: min(40rem, 55vh);
  }

  @media only screen and (min-height: 1080px) {
    --video-height: 45rem;
  }
`

export const LogoImg = styled.img`
  /* width: min(80vw, 800px);
  height: min(33vw, 333px);
  margin-top: max(-4%, -40px);
  margin-bottom: max(-5%, -48px); */

  width: min(80vw, 800px);
  height: min(8vw, 80px);
  margin-top: max(-4%,-40px);
  margin-bottom: max(-5%,-48px);
  position: inherit;
  z-index: 200;
  margin-bottom: min(3rem, 4vw);
  filter: drop-shadow(0px 0px 5px rgba(0,0,0,.5));
`

export const LogoSubtext = styled.h1`
  position: absolute;
  top: calc(100% - .75em);
  left: 50%;
  font-size: min(3em, 5vw);
  filter: drop-shadow(0px 0px 5px rgba(0,0,0,.5));
`

export const LogoWrapper = styled.div`
  position: relative;
  margin-bottom: -1%;
`

export const VideoBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  opacity: .5;
  width: 100%;
  height: var(--video-height);
  z-index: -1;
`

export const SocialsWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`