import React, { Component } from 'react'
import axios from 'axios'
import path from 'path'

import { AlbumContainer, AlbumCover, AlbumDescriptionContainer, AlbumCoverEffect, AlbumLinksBox, AlbumLinksSVG, AlbumLinksContainer } from '../../styled/discographyStyled'
import getSpotifyToken from '../../functions/spotifyToken'

const delay = t => new Promise(resolve => setTimeout(resolve, t))

class Album extends Component {
  constructor() {
    super()
    this.state = {
      audioActive: false
    }

    this.audioElement = undefined

    this.coverRef = React.createRef()
    this.fadeInAudio = undefined
    this.fadeOutAudio = undefined
  }

  componentDidMount = () => this.setSpotifyPreview()

  componentWillUnmount = () => {
    this.audioElement?.pause()
  }

  setSpotifyPreview = async (retry = false) => {
    try {
      if (this.props.params.albumID) this.coverRef.current.scrollIntoView()

      if (!sessionStorage.getItem("spotify_token")) await getSpotifyToken()

      const spotifyURI = this.props.info.spotifyURI,
            id = spotifyURI.split("/")[spotifyURI.split("/").length - 1]

      const XHRconfig = {
        method: "GET",
        url: `https://api.spotify.com/v1/albums/${id}/tracks`,
        headers: { Authorization: "Bearer " + sessionStorage.getItem("spotify_token") }
      }

      return axios(XHRconfig)
      .then(response => {
        const preview_url = response.data.items[0].preview_url
        this.audioElement = new Audio(preview_url)
        this.audioElement.volume = 0

        // add event listeners to start audio playback
        // otherwise it is blocked by browsers
        // also scroll to the album
        if (this.props.params.albumID === id) return delay(500)
        .then(() => {
          window.addEventListener("mousemove", this.listenUserInteraction)
          window.addEventListener("keydown", this.listenUserInteraction)
          window.addEventListener("mouseover", this.listenUserInteraction)
          window.addEventListener("scroll", this.listenUserInteraction)
        })
      })
      .catch(err => {
        if (err.response?.status === 401 && !retry) {
          return getSpotifyToken()
          .then(() => this.setSpotifyPreview(true))
          .catch(err => { throw err })
        }
      })
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Listen for any user interaction and start audio playback
   * Remove all event listeners first
   */
  listenUserInteraction = () => {
    window.removeEventListener("mousemove", this.listenUserInteraction)
    window.removeEventListener("keydown", this.listenUserInteraction)
    window.removeEventListener("mouseover", this.listenUserInteraction)
    window.removeEventListener("scroll", this.listenUserInteraction)
    this.toggleAudio()
  }

  /**
   * toggle the playback of audio
   * of top of that the state for visuals will be updated
   * and the albumid will be added to history if it wasn't already
   * @returns {function}
   */
  toggleAudio = () => {
    const el = this.audioElement

    if (el && !this.state.audioActive) {
      this.startPlayer()
    } else {
      this.stopPlayer()
    }
  }

  startPlayer = async () => {
    const pathname = this.props.history.location.pathname,
          spotifyURI = this.props.info.spotifyURI,
          id = spotifyURI.split("/")[spotifyURI.split("/").length - 1],
          el = this.audioElement

    if (this.props.params.albumID !== id && pathname.split("/").length > 2) return;

    this.props.history.push(path.join("/discography", id))
    this.setState({ audioActive: true })
    clearInterval(this.fadeOutAudio)

    el.volume = 0
    try {
      window.focus()
      await el.play()
      // fadein audio
      this.fadeInAudio = setInterval(() => {
        if (el.volume < .4) el.volume += 0.01
        if (el.volume < .6) el.volume += 0.02
        if (el.volume < .8) el.volume += 0.03
        else if (el.volume < 0.96) el.volume += 0.04
        else {
          clearInterval(this.fadeInAudio)
        }
      }, 30)

      return delay((el.duration - 2) * 1000).then(() => {
        // fade out audio
        this.stopPlayer()
      })
    } catch (err) {
      this.stopPlayer()
    }
  }

  stopPlayer = () => {
    const el = this.audioElement

    this.props.history.push("/discography")
    this.setState({ audioActive: false })
    clearInterval(this.fadeInAudio)

    // fadeout audio
    this.fadeOutAudio = setInterval(() => {
      if (el.volume > .8) el.volume -= 0.04
      if (el.volume > .6) el.volume -= 0.03
      if (el.volume > .4) el.volume -= 0.02
      else if (el.volume > 0.01) el.volume -= 0.01
      else {
        clearInterval(this.fadeOutAudio)
        el.volume = 0
        el.currentTime = 0
        el.pause()
      }
    }, 30)
  }

  // when an album is released add function for embed album if this.props.info.type === album

  render() {
    const v = this.props.info

    return (
      <AlbumContainer>
        <AlbumCoverEffect onClick={this.toggleAudio} className={this.state.audioActive ? "playing" : undefined} ref={this.coverRef}>
          <AlbumCover src={v.cover} alt={v.title} />
          <div></div>
        </AlbumCoverEffect>
        <AlbumDescriptionContainer>
          <h1> { v.title } </h1> 
          <p> { v.releaseDate.toLocaleDateString() } </p>
          <p> { v.type } </p>
          <AlbumLinksContainer>
            {
              v.spotifyURI &&
              <AlbumLinksBox onClick={() => window.open(v.spotifyURI, "_blank")}>
                <AlbumLinksSVG alt="Apple-music-logo">
                <svg id="Bold" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m12 24c6.624 0 12-5.376 12-12s-5.376-12-12-12-12 5.376-12 12 5.376 12 12 12zm4.872-6.344v.001c-.807 0-3.356-2.828-10.52-1.36-.189.049-.436.126-.576.126-.915 0-1.09-1.369-.106-1.578 3.963-.875 8.013-.798 11.467 1.268.824.526.474 1.543-.265 1.543zm1.303-3.173c-.113-.03-.08.069-.597-.203-3.025-1.79-7.533-2.512-11.545-1.423-.232.063-.358.126-.576.126-1.071 0-1.355-1.611-.188-1.94 4.716-1.325 9.775-.552 13.297 1.543.392.232.547.533.547.953-.005.522-.411.944-.938.944zm-13.627-7.485c4.523-1.324 11.368-.906 15.624 1.578 1.091.629.662 2.22-.498 2.22l-.001-.001c-.252 0-.407-.063-.625-.189-3.443-2.056-9.604-2.549-13.59-1.436-.175.048-.393.125-.625.125-.639 0-1.127-.499-1.127-1.142 0-.657.407-1.029.842-1.155z"/></svg>
                </AlbumLinksSVG>
              </AlbumLinksBox>
            }
            {
              v.appleMusicURI &&
              <AlbumLinksBox onClick={() => window.open(v.appleMusicURI, "_blank")}>
                <AlbumLinksSVG alt="Apple-music-logo">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 456.008 560.035"><path d="M380.844 297.529c.787 84.752 74.349 112.955 75.164 113.314-.622 1.988-11.754 40.191-38.756 79.652-23.343 34.117-47.568 68.107-85.731 68.811-37.499.691-49.557-22.236-92.429-22.236-42.859 0-56.256 21.533-91.753 22.928-36.837 1.395-64.889-36.891-88.424-70.883-48.093-69.53-84.846-196.475-35.496-282.165 24.516-42.554 68.328-69.501 115.882-70.192 36.173-.69 70.315 24.336 92.429 24.336 22.1 0 63.59-30.096 107.208-25.676 18.26.76 69.517 7.376 102.429 55.552-2.652 1.644-61.159 35.704-60.523 106.559M310.369 89.418C329.926 65.745 343.089 32.79 339.498 0 311.308 1.133 277.22 18.785 257 42.445c-18.121 20.952-33.991 54.487-29.709 86.628 31.421 2.431 63.52-15.967 83.078-39.655"/></svg>
                </AlbumLinksSVG>
              </AlbumLinksBox>
            }
            <span/>
          </AlbumLinksContainer>
        </AlbumDescriptionContainer>
      </AlbumContainer>
    )
  }
}

export default Album