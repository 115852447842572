import { Component } from 'react'

import { HeaderContainer } from '../../styled/photoAlbumStyled'

import logoTextHor from '../../assets/logos/logo-text-horizontal.svg'

class PhotoAlbumHeaderText extends Component {
  render() {
    return (
      <HeaderContainer>
        <img src={logoTextHor} alt="lucas-nicholas" width="600" height="294" />
        <h1> The photo album! </h1>
      </HeaderContainer>
    )
  }
}

export default PhotoAlbumHeaderText